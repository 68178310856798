<template>
  <crud-editor
    ref="editor"
    :definition="definition"
    :is-edit="false"
    :save="saveData"
    :return-to="returnTo"
    :item-type="entity"
  />
</template>

<script>
import CrudEditor from '@/components/crud/Editor';
import formMixin from './form-mixin';

export default {
  components: {
    CrudEditor
  },
  mixins: [formMixin],
  methods: {
    saveData(form) {
      const role = this.createItemFromFormData(this, form);
      return this.$store.dispatch(this.entityStore + '/persistItem', role);
    }
  }
};
</script>
